

import 'jquery'

import 'angular'
import 'angular-animate'
import 'angular-messages'
import 'angular-sanitize'

import 'angular-ui-router'

import 'angular-ui-bootstrap/index'
import 'angular-confirm/angular-confirm'

import 'ui-select/dist/select.css'
import 'ui-select/dist/select.js'

import 'angular-upload'
import 'angular-upload/src/directives/btnUpload.min.css'

import 'bootstrap/dist/js/bootstrap'
import 'bootstrap/dist/css/bootstrap.css'

import 'font-awesome/scss/font-awesome.scss'

import 'angular-grid/dist/angular-grid.js'
import 'angular-grid/dist/angular-grid.css'

import 'angular-formly/dist/formly'

import 'moment'

import 'radio'

import 'lodash'

import 'chart.js'
import 'angular-chart.js/dist/angular-chart.min.js'
import 'angular-chart.js/dist/angular-chart.css'

import 'bootstrap-switch/dist/js/bootstrap-switch'
import 'bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.css'
import 'angular-bootstrap-switch/dist/angular-bootstrap-switch'